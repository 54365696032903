import React from "react";
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import partners from '../../img/logo-partners.png';
import { useTranslation } from 'react-i18next';

function Footer() {

    const { t, i18n } = useTranslation();

    return (
    <div id="footer">
       <Container id="fede-footer">
            <Row id="partners">
                <Col className="text-center">
                    <Image src={partners} title="Logo partners" fluid />
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
            </Row>
      </Container>
      <Container fluid className="footer-copyright">
          <Container>
            <Nav
                activeKey="/home"
                //onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >    
                <Nav.Item>
                    <Nav.Link eventKey="disabled" disabled>Copyright © {new Date().getFullYear()} Fédération Européenne Des Ecoles</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-lg-block">
                    <Nav.Link href="https://fede.education/statuts-fede/" target="_blank">STATUS FEDE</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-lg-block">
                    <Nav.Link href="https://fede.education/reglement-interieur/" target="_blank">{t('footer.rules')}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-lg-block">
                    <Nav.Link href="https://fede.education/faq/" target="_blank">FAQ</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-lg-block">
                    <Nav.Link href="https://fede.education/contact-us/" target="_blank">CONTACT</Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-none d-lg-block">
                    <Nav.Link href="https://fede.education/mentions-legales/" target="_blank">{t('footer.legal')}</Nav.Link>
                </Nav.Item>
            </Nav>
            </Container>
      </Container>
    </div>
    );
}
  
export default Footer;