import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware  from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import auth from './reducers/auth'
import { createBrowserHistory } from "history";

let middleware = [ 
    routerMiddleware(createBrowserHistory()), 
    thunkMiddleware, 
]

if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, createLogger()];
}

const store = createStore(
    combineReducers({
        //router: connectRouter(history),
        auth,
        /* Replace book with the name of the resource type */
    }),
    applyMiddleware(...middleware)
);

export default store;