import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'fr',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      fr: {
        translation: {
          header: {
            language: 'Langues',
            apps: 'Applications FEDE',
            logout: 'Se deconnecter',
            login: 'Se connecter',
          },
          footer: {
            rules: 'REGLEMENT INTERIEUR',
            legal: 'MENTION LEGAL'
          },
          home: {
            space: 'ESPACE MEMBRE',
            update: 'Modifier votre profil personnel',
            schoolupdate: 'Modifier les informations de votre école',
            index: 'Accueil'
          }
        }
      },
      en: {
        translation: {
          header: {
            language: 'Languages',
            apps: 'FEDE Applications',
            logout: 'Logout',
            login: 'Login',
          },
          footer: {
            rules: 'INTERNAL RULES',
            legal: 'LEGAL NOTICE'
          },
          home: {
            space: 'MEMBER SPACE',
            update: 'Update your profile',
            schoolupdate: 'Update school informations',
            index: 'Homepage'
          }
        }
      },
    }
  });

export default i18n;