import React from "react";
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faPhoneAlt,  } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { AuthenticationState } from 'react-aad-msal';
import fedeWorld from '../../img/fede-world.png';
import FrenchFlag from '../../img/flag-fr.png';
import EnglishFlag from '../../img/flag-en.png';
import { useTranslation } from 'react-i18next';

const lngs = {
    en: { nativeName: 'English', flag: EnglishFlag },
    fr: { nativeName: 'French', flag: FrenchFlag }
};

function Header({ authenticationState, login, logout, account }) {

    const { t, i18n } = useTranslation();

    return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <div className="container">
            <Navbar.Brand href="https://fede.education" target="_blank" rel="noopener noreferrer">
                <Image src={fedeWorld} title="Logo partners" fluid style={{height: "40px"}} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
                <NavDropdown title={t('header.language')} id="collasible-nav-dropdown">
                    {Object.keys(lngs).map((lng) => (
                        <NavDropdown.Item key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                        {/*console.log(lngs[lng])*/}
                        <Image src={lngs[lng].flag} title="country flag" fluid style={{height: "14px", margin: "4px"}} />
                        {lngs[lng].nativeName}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
                {/*<NavDropdown title="Français" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="#"><img src={frFlag} alt="Français" /> Français</NavDropdown.Item>
                    <NavDropdown.Item href="#"><img src={enFlag} alt="English" /> English</NavDropdown.Item>
    </NavDropdown>*/}
            </Nav>

            <Nav>
                {
                    authenticationState === AuthenticationState.Authenticated ? 
                    (
                        <NavDropdown title={account.account.name} id="collasible-nav-dropdown">
                            {/*<NavDropdown.Item href="https://account.activedirectory.windowsazure.com/">{t('header.apps')}</NavDropdown.Item>
                            <NavDropdown.Divider />*/}
                            <NavDropdown.Item href="#" onClick={logout}>{t('header.logout')}</NavDropdown.Item>
                        </NavDropdown>

                    ) : (
                        <Nav.Link href="#" onClick={login}>
                            <span>
                                <FontAwesomeIcon icon={faSignInAlt} /> {t('header.login')}
                            </span>
                        </Nav.Link>
                    )
                }
                <Nav.Link 
                    href="https://www.fede.education/contact/" 
                    target="_blank"
                >
                    <span>
                        <FontAwesomeIcon icon={faPhoneAlt} /> Contact
                    </span>
                </Nav.Link>
                <Nav.Link 
                    href="https://www.facebook.com/fede.education" 
                    target="_blank"
                >
                    <FontAwesomeIcon icon={faFacebookF} />
                </Nav.Link>
                <Nav.Link 
                    href="https://twitter.com/FEDE_OING" 
                    target="_blank"
                >
                   <FontAwesomeIcon icon={faTwitter} />
                </Nav.Link>
                <Nav.Link 
                    href="https://www.linkedin.com/school/federation-for-education-in-europe/" 
                    target="_blank"
                >
                   <FontAwesomeIcon icon={faLinkedinIn} />
                </Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </div>
        </Navbar>
    );
}
  
export default Header;