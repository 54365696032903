import React, { useState } from 'react';
import spinner from '../../img/spinner-fede.gif'
import { Container, Breadcrumb, Modal, Button, Form } from 'react-bootstrap';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import schoolLogo from '../../img/generic-school-logo.png';
import interLogo from '../../img/interculturalite.jpg';
//import bannerPeps from '../../img/banner-peps_fr.gif';
import { Helmet } from 'react-helmet'

const SpinnerFede = () => {
    return (
        <div id="spinner">
            <img src={spinner} width="100" alt="Spinner" /><span className="sr-only">Loading...</span>
        </div>
    )
}

const Subheader = ({ params }) => {
    
    return (
        <Container fluid id={ params.bg } className="subheader d-none d-sm-block" style={{ backgroundImage: "url('/img/bg-login.jpg')" }}>
            <h1 className="text-center">{ params.title.toUpperCase() }</h1>
        </Container>
    )
}

const BreadcrumbFede = ({ crumbs }) => {

    return (
    <Container fluid id="breadcrumb" className="shadow-sm p-3 mb-5 bg-white rounded d-none d-sm-block">
        <Container>
            <Breadcrumb>
                <Breadcrumb.Item href="https://www.fede.education/">FEDEration for European Education</Breadcrumb.Item>
                {crumbs.map(({ name, path }, key) =>
                    key + 1 === crumbs.length ? (
                        <Breadcrumb.Item key={key} active>
                            {name}
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item key={key} href={path}>
                            {name}
                        </Breadcrumb.Item>
                    )
                )}
            </Breadcrumb>
        </Container>
    </Container>
    )

}

const Error404 = () => {
    return (
    <Container>
        <Helmet
            titleTemplate="%s | Intranet FEDE"
        >
            <title>404</title>
        </Helmet>
        <div className="heading_font text-center align-middle error_page">
            <div className="error_404">404</div>
            <div className="h2">The page you are looking for does not exist.</div>
        </div>
    </Container>
    )

}

const ModalUpdateFede = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-danger">
            27/06/2024 : Nouveautés / Latest news
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="allModal">

        <span>

          Dans le cadre de la rentrée 2024-2025, les équipes de la FEDE vous présentent les nouveautés pédagogiques et les nouveaux outils mis à la disposition des écoles et organismes de formation membres. La première session d’examens concernée par ces évolutions est la session de janvier 2025.
          <br/><br/>
          

          <span className="secondTitle">Nouveautés pédagogiques</span>
            <li>Nouveaux diplômes</li>
            <li>Modification d'intitulé</li>
            <li>Culture et citoyenneté européennes – Unité d’Enseignement A (UE A)</li>
            <li>Expertise professionnelle - unité d’Enseignement D (UE D)</li>
            <li>Diplôme supprimé</li>
          <br/>

          <span className="secondTitle">Nouveaux services et outils</span>
            <li>Documents et guides</li>
            <li>Formation de formateur</li>
          <br/>

          Nous vous remercions de consulter le document synthétique suivant : <a href="https://cdefede.sharepoint.com/:b:/s/CommunicationPdagogie/EdCoo7SbSWJFmtM8-l6jTogBcX3mMPyKHiDJdJ7FQIYKvg?e=ajl4Jg" target="_blank">FEDE - Nouveautés pédagogiques - 2024-2025</a>

        </span>
        <br/><hr/><br/>

        <span>

        For the start of the 2024-2025 school year, the FEDE teams would like to present you with the new pedagogical innovations and the new tools available to member schools and training centres. The first examination session affected by these changes is the January 2025 session.
          <br/><br/>
          

          <span className="secondTitle">New pedagogical Features</span>
            <li>New degrees</li>
            <li>Change of title</li>
            <li>European Culture and Citizenship - Teaching Unit A (TU A)</li>
            <li>Updating of teaching guideline - Teaching Unit D (TU D)</li>
            <li>Degree Withdrawn</li>
          <br/>

          <span className="secondTitle">New Services and Tools</span>
            <li>Documents and Guidelines (5 documents)</li>
            <li>Training of trainers</li>
          <br/>

          Please consult the following summary document : <a href="https://cdefede.sharepoint.com/:b:/s/CommunicationPdagogie/EaONZKYcpVtBh7m-9RjZnpQBYkKtXRLWtAfl40eItBlhew?e=xbB3b0" target="_blank">FEDE - Educational News - 2024-2025</a>

        </span>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant="fede">Fermer</Button>
        </Modal.Footer>
      </Modal>
    );
}

const ModalFede = (props) => {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-danger">
            Attention
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            Votre compte n'est associé à aucune école.
            Merci de contacter la FEDE à l'adresse <a href="mailto:mailbox@fede.education">mailbox@fede.education</a> pour corriger cela, en indiquant le nom de votre école.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant="fede">Fermer</Button>
        </Modal.Footer>
      </Modal>
    );
}

const ModalChooseSchoolFede = (props) => {
    console.log(props)
    const [schoolId, setSchoolId] = useState(null);
    const [validated, setValidated] = useState(false);
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
          
        } 
        setValidated(true);
        props.onSchoolSelected(schoolId)
        props.onHide();
        
        event.preventDefault();
        event.stopPropagation();
    }

    const handleChange = (event) => {
        setSchoolId(event.target.value)
        event.preventDefault();
        event.stopPropagation();
    }

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="text-info">
            Attention
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="text-justify">Votre compte est associé à plusieres écoles. Merci de selection quelle école vous souhaitez gerer.</p>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="selectSchool">
                    <Form.Label>Choisir l'école</Form.Label>
                    <Form.Control as="select" onChange={handleChange}>
                        <option>Selectionner une école  </option>
                        {props.schools.map((school, idx) => (
                            <option key={idx} value={school.id}>{school.name}</option>
                        ))}
                    </Form.Control>    
                </Form.Group>
                <Button type="submit" variant="fede">Valider</Button>
            </Form>
        </Modal.Body>
      </Modal>
    );
}
  
const SchoolContext = React.createContext(null);

const SidebarRight = () => {
    return(
        <div>
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName="FEDE_OING"
                options={{height: 500}}
            />
      </div>
    );
}

const SidebarLeft = () => {
    return (
    <div>
        <div className="shadow p-3 mb-5 bg-white rounded fede-box"><img src={schoolLogo} className="mx-auto d-block" alt="Logo"/></div>
        {/* <div className="shadow mb-5 bg-white rounded">
          <a href="https://peps.fede.education/" title="Programme PEPS" target="_blank" rel="noopener noreferrer">
            <img src={bannerPeps} className="mx-auto d-block img-fluid" alt="Banner Programme Peps"/>
          </a>
        </div> */}
    </div>
    );
}

export {
    SpinnerFede,
    Subheader,
    BreadcrumbFede,
    Error404,
    ModalFede,
    ModalChooseSchoolFede,
    SchoolContext,
    SidebarRight,
    SidebarLeft,
    ModalUpdateFede
};
