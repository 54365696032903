import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import store from './reduxStore';
import Header from './Components/Header';
import Main from './Components/Main';
import Footer from './Components/Footer';
import Login from './Components/Login';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './provider/authProvider';
import {BrowserRouter as Router } from "react-router-dom";
import './i18n';

function App() {

    return (
    <AzureAD provider={authProvider} reduxStore={store} forceLogin={true}>

        {
            ({login, logout, authenticationState, error, accountInfo}) => {
            switch (authenticationState) {
                case AuthenticationState.Authenticated:
                return (<Router><Header authenticationState={authenticationState} login={login} logout={logout} account={accountInfo} /><Main /><Footer /></Router>);
                case AuthenticationState.Unauthenticated:
                return (
                    <Router>
                        <Header authenticationState={authenticationState} login={login} logout={logout} account={accountInfo}  />
                        <Login login={login} error={error} authenticationState={authenticationState} />
                        <Footer />
                    </Router>
                );
                // case AuthenticationState.InProgress:
                // return (<p>Authenticating...</p>);
            }
            }
        }
    </AzureAD>
    );
}

  
export default App;
  
