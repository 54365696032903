import React, { useState, useEffect } from "react";
import { Accordion, Card, Button, Container, Row, Col } from 'react-bootstrap';
//import { authProvider } from '../../provider/authProvider';
import { SpinnerFede, SidebarRight, ModalFede, ModalUpdateFede }  from '../Common';
//import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'
import { getSharepointList, groupSpListByCategories, listEducationSchools } from '../../services/GraphService';
import { useTranslation } from 'react-i18next';

function Home() {
    const [isLoading, setIsLoading] = useState(true);
    const [schools, setSchools] = useState(null);
    const [categories, setCategories] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
      
      const fetchData = async () => {
          try {
            const educationSchools =  await listEducationSchools();
            const res = await getSharepointList();
            const grouped =  groupSpListByCategories(res);
            setCategories(grouped);
            setSchools(educationSchools);
            if(!educationSchools || educationSchools.length === 0){
              setModalShow(true);
            }
            setIsLoading(false);
      
          } catch (error) {
              console.error(error);
          }

      };

      fetchData();

    }, []);
    
    return (    
      <Container style={{marginTop: "20px", marginBottom: "200px"}}>
        <Helmet
            titleTemplate="%s | Intranet FEDE"
        >
            <title>{t('home.index')}</title>
        </Helmet>
        <Row>
          <Col lg={6} md={12}>
            {isLoading && (<SpinnerFede />)}
            {!isLoading && (
              <React.Fragment>
                {!schools && (
                  <ModalFede 
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                )}
                {<ModalUpdateFede 
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />}
                <h2 style={{fontSize: "1.5rem", color: "white", backgroundColor: "#86bf57", padding: ".75rem 1.25rem"}}>{t('home.space')}</h2>
                {/*<ul style={{padding: "1.25rem"}}>
                  <li key="account">
                    <Link
                      key="account-link"
                      to={{
                        pathname: `/account/${authProvider.account.accountIdentifier}`,
                        //search: "?sort=name",
                        //hash: "#the-hash",
                        state: { fromDashboard: true }
                      }}
                    >
                      {t('home.update')}
                    </Link>
                  </li>
                  {schools && schools.map((school, idx) => (
                    <li key={idx}>
                      <Link
                        key={idx}
                        to={{
                          pathname: `/schools/${school.id}`,
                          //search: "?sort=name",
                          //hash: "#the-hash",
                          state: { fromDashboard: true }
                        }}
                      >
                        {t('home.schoolupdate')} : { school.displayName}
                      </Link>
                    </li>
                      ))}
                </ul>*/}
                {categories && (
                  <Accordion>
                    {Object.keys(categories).map((cat) => (  
                      <Card key={cat}>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey={cat.trim()}>
                            {cat}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={cat.trim()}>
                          <Card.Body>
                            <ul>
                              {categories[cat].map(item => (
                                <li key={item.id}>
                                  <a href={ item.Docurl } title={ item.Title } target="_blank" rel="noopener noreferrer">{ item.Title }</a>
                                </li>
                              ))}
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                  ))}
                  </Accordion>
                )}
              </React.Fragment>
            )}
          </Col>
          <Col className="d-none d-lg-block"><SidebarRight /></Col>
        </Row>
      </Container>
    );
}

export default Home;