import "isomorphic-fetch";
import { Client } from "@microsoft/microsoft-graph-client";
import { authProvider } from '../provider/authProvider';


const pedagogieSiteId = "cdefede.sharepoint.com,154568af-45ff-4d4f-982e-f56b26eafd04,e9b45774-b78a-4da1-a710-0adff9161baa";
const intranetListId  = "78b5264e-2061-41f1-aca8-16980cdf6155";
const pedagogieSiteUrl  = "https://cdefede.sharepoint.com/:b:/r/sites/CommunicationPdagogie/Documents%20partages";

const getAuthenticatedClient = async (version = null)  => {
    const token =  await authProvider.getAccessToken();

    const client = Client.init({
        defaultVersion: (version) ? version : "v1.0",
        debugLogging: false,
        authProvider: (done) => {
            done(null, token.accessToken);
        },
    });
  
    return client;
}

const groupSpListByCategories = (list) => {
    return list.reduce((result, currentValue) => {
        (result[currentValue.fields.Category] = result[currentValue.fields.Category] || []).push(currentValue.fields);
        return result;
    }, {}); 
}
  
const getSharepointList = async () => {
    try {
        const siteId = pedagogieSiteId;
        const listId = intranetListId;

        const client = await getAuthenticatedClient();

        const response = await client.api(`/sites/${siteId}/lists/${listId}/items`)
                              .expand("fields")
                              .get();
                          
        return response.value;

    } catch (error) {
        console.error(error);
    }
}

const path_ext = (absPath) => {
    return absPath.replace(pedagogieSiteUrl, "").split('?')[0]
}

const getSharepointItem = async (absPath) => {
    try {

        const siteId = pedagogieSiteId;
        const relPath = path_ext(absPath);

        const client = await getAuthenticatedClient();

        const response = await client.api(`/sites/${siteId}/drive/root:/${relPath}`)
                              .select("id", "name", "webUrl")
                              .get();
                          
        return response;

    } catch (error) {
        console.error(error);
    }
}

const getEducationSchool = async (schoolId) => {
    try {

        const client = await getAuthenticatedClient();
      
        let response = await client.api(`/education/schools/${schoolId}`)
                              .get();

        return response;

    } catch (error) {
        console.error(error);
    }
}

const listEducationSchools = async () => {
    try {

        const client = await getAuthenticatedClient("beta");
        
        let response = await client.api(`/education/me/schools`)
                              .get();

        return response.value;

    } catch (error) {
        console.error(error);
    }
}


const getMe = async () => {
    try {

        const client = await getAuthenticatedClient();
      
        let response = await client.api(`/me`)
        .select("displayName", "givenName", "surname")
        .get();

        return response;

    } catch (error) {
        console.error(error);
    }
}


const getUserSchoolsInToken = async () => {
    const idToken =  await authProvider.getIdToken();
       
    if(idToken.idToken.claims.hasOwnProperty("extn.fedeSchoolGid")){
      return JSON.parse(idToken.idToken.claims["extn.fedeSchoolGid"]);
    } 
}

export {
    getSharepointList,
    getEducationSchool,
    listEducationSchools,
    groupSpListByCategories,
    getUserSchoolsInToken,
    getSharepointItem,
    getMe
}