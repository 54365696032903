import React, { useState } from "react";
import { Container, Col, Row, Image, Card, Button  } from 'react-bootstrap';
import home from '../../img/home.png';
import fedeWorld from '../../img/fede-world.png';
import { Helmet } from 'react-helmet'
//import { authProvider } from '../../provider/authProvider';
import { AuthenticationState } from 'react-aad-msal';

  
function Login({ login, error, authenticationState }) {
    const [validated, setValidated] = useState(false);
    //const [email, setEmail] = useState("");
 


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        //console.log(form.checkValidity())
        // if (form.checkValidity() === false) {
        //   event.preventDefault();
        //   event.stopPropagation();
        // } 
        // const authenticationParameters = {...getAuthenticationParameters(), loginHint:  email}
        // setAuthenticationParameters(authenticationParameters);
        login();
       
        setValidated(true);
    }

    return (
        <Container>
            <Helmet
                titleTemplate="%s | Intranet FEDE"
            >
                <title>Connexion</title>
            </Helmet>
            {authenticationState !== AuthenticationState.InProgress && (
            <React.Fragment>
                <Row>
                    <Col><Image className="rounded float-left" src={home} title="Home" style={{ width: "30px" }}/><h4>Bienvenue dans votre Espace membre</h4></Col>
                </Row>
                <Row id="login">
                    <Col className="align-self-center d-none d-sm-none d-md-block">
                        <Image src={fedeWorld} title="Logo partners" fluid />
                    </Col>
                    <Col className="align-self-center" md={6}>
                        <Card>
                            <Card.Body>
                                {/*<Form noValidate validated={validated} onSubmit={handleSubmit}>
                                     <Form.Group controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            placeholder="Adresse e-mail" 
                                            value={email}
                                            required
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Merci d`entrer une adresse e-mail valide
                                        </Form.Control.Feedback>
                                    </Form.Group> 
                                    <Button variant="fede" type="submit">
                                        Se connecter
                                    </Button> 
                                </Form>*/}
                                <p className="font-weight-bolder text-justify">Vous devez être identifié pour accéder à cette section.</p>
                                <p>
                                    {error && (
                                        <p className="text-danger">Vous n'avez pas la permission. Contacter la FEDE pour demander les droits d'accès à l'intranet : <a href="mailto:mailbox@fede.education">mailbox@fede.education</a></p>
                                    ) }
                                </p>
                                <Button variant="fede" type="submit" size="lg" onClick={handleSubmit}>
                                    Se connecter
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
            )}
        </Container>
    );
}
  
export default Login;