import React from "react";
import routes from "../../routes";
import {
  Switch,
  Route
} from "react-router-dom";
import { Subheader, BreadcrumbFede, Error404 }  from '../Common';


function Main() {

    return (    
      <div>
        <Switch>
          {routes.map(({ path, name, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={props => {
                const crumbs = routes
                  .filter(({ path }) => props.match.path.includes(path))
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                        (path, param) => path.replace(
                          `:${param}`, props.match.params[param]
                        ), path
                        )
                      : path,
                    ...rest
                  }));
                return (
                  <React.Fragment>
                    <Subheader params={{title: crumbs[crumbs.length - 1].name, bg: crumbs[crumbs.length - 1].bg}} /> 
                    <BreadcrumbFede crumbs={crumbs} />
                    <Component {...props}  />
                  </React.Fragment>
                );
              }}
            />
          ))}
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </div>
 
    );
}

export default Main;