// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from "msal";

// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/fede.education',
    clientId: '0fd4db93-fcca-4ccf-806b-38c6961bc1a0',
    redirectUri: window.location.origin,
    validateAuthority: true, 
    postLogoutRedirectUri: 'https://www.fede.education/',
    //navigateToLoginRequestUrl: false
  },
  system: {},
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

if(process.env.NODE_ENV === 'development') {
  config.system.logger = new Logger(
    (logLevel, message, containsPii) => {
      console.log("[MSAL]", message);
    },
    {
      level: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  );
}

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'https://graph.microsoft.com/.default',
  ],
  state: window.location.href.replace(window.location.hash,""),
  //forceRefresh: true,
  prompt: 'select_account',
  extraScopesToConsent: [],
  extraQueryParameters: {domain_hint: 'fede.education'},
  loginHint: null
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)